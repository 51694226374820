<template>
    <div class="logs">
        <span>
            <h2>Logs</h2>
            <p>Actual version : v1.1.0</p>
        </span>
        <div>
            <ul>
                <h3>v.1.1.0</h3>
                <li data-infos="+">Added basic server system</li>
                <li data-infos="+">Invite system</li>
            </ul>
            <ul>
                <h3>v1.0.6</h3>
                <li data-infos="+">Started responsive</li>
                <li data-infos="+">Style changes</li>
                <li data-infos="+">Delete button on message</li>
            </ul>
            <ul>
                <h3>v1.0.5</h3>
                <li data-infos="+">Auto-Detect link in message</li>
                <li data-infos="+">Added image formating button</li>
                <li data-infos="-">Removed all console.log</li>
                <li data-infos="+">Added 'Is Typing'</li>
            </ul>
            <ul>
                <h3>v1.0.4</h3>
                <li data-infos="+">Added Support Syntax Markdown</li>
                <li data-infos="+">DMs ordered by latest message</li>
            </ul>
            <ul>
                <h3>v1.0.3</h3>
                <li data-infos="x">Fixed a bug that created infinite DMs even if the channel already existed</li>
                <li data-infos="+">Added /about</li>
                <li data-infos="+">Added Logs in About page</li>
            </ul>
            <ul>
                <h3>v1.0.2</h3>
                <li data-infos="+">Added DMs</li>
            </ul>
            <ul>
                <h3>v1.0.1</h3>
                <li data-infos="+">Added Friend System</li>
                <li data-infos="+">Added Friend Request</li>
            </ul>
            <ul>
                <h3>v1.0.0</h3>
                <li data-infos="+">Status</li>
                <li data-infos="">Settings Page</li>
                <li data-infos="+">DSES (Dynamical Socket Emiting System)</li>
                <li data-infos="+">Profile Page</li>
                <li data-infos="+">Login</li>
                <li data-infos="+">Register</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>