<template>
  <div class="about">
      <h2>About</h2>
      <div class="description">
        <p>Campfire is an alternative to Discord made by <a href="https://github.com/corentinmace">Corentin Macé</a> as a personal project.</p>
        <p>The Frontend is made with VueJS and Firebase, and Socket.IO and Express for the backend.</p>
      </div>
  </div>
  <Logs />
</template>

<script>
// @ is an alias to /src
import Logs from '../components/Logs.vue'


export default {
  name: 'About',
  components: {
    Logs
  }
}
</script>
